import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import Menu from "@mui/joy/Menu";
import MenuButton from "@mui/joy/MenuButton";
import MenuItem from "@mui/joy/MenuItem";
import Dropdown from "@mui/joy/Dropdown";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";
import DeleteForever from "@mui/icons-material/DeleteForever";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import { ViewEmployeeDto } from "../../types";
import { DialogTitle, Tooltip, useColorScheme } from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import { DeleteModalBox } from "../globalCards/ModalBox";
import UpdateEmployeForm from "./UpdateEmployeForm";
import { LoaderBox } from "../globalCards/LoaderBox";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
export default function EmployeeViewCard() {
  const {
    useViewEmployeeListMutation,
    useDeleteEmployeeMutation,
    useUpdateEmployeeMutation,
    useProjectViewListMutation,
    error,
  } = useApi();
  const { mode } = useColorScheme();
  const [visibleColumns, setVisibleColumns] = useState([
    {
      checked: true,
      width: 60,
      name: "ID",
    },
    {
      checked: true,
      width: 140,
      name: "Name",
    },
    {
      checked: true,
      width: 140,
      name: "Email",
    },
    {
      checked: true,
      width: 140,
      name: "Project",
    },
    {
      checked: true,
      width: 140,
      name: "Password",
    },
    {
      checked: true,
      width: 140,
      name: "Phone",
    },
    {
      checked: true,
      width: 140,
      name: "Address",
    },
    {
      checked: true,
      width: 140,
      name: "Designation",
    },
    {
      checked: true,
      width: 140,
      name: "Emirates ID",
    },
    {
      checked: true,
      width: 140,
      name: "Basic Salary",
    },
    {
      checked: true,
      width: 140,
      name: "DOB",
    },
    {
      checked: true,
      width: 140,
      name: "Emirates Expiry Date",
    },
    {
      checked: true,
      width: 140,
      name: "Joining Date",
    },
    {
      checked: true,
      width: 140,
      name: "Overtime Price Hourly",
    },
    {
      checked: true,
      width: 140,
      name: "Passport No.",
    },
    {
      checked: true,
      width: 140,
      name: "Passport Expiry",
    },
    {
      checked: true,
      width: 140,
      name: "Salary Cert. Date",
    },
    {
      checked: true,
      width: 140,
      name: "Salary Cert. Expiry",
    },
    {
      checked: true,
      width: 140,
      name: "Username",
    },
  ]);
  const [rows, setRows] = useState<ViewEmployeeDto[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [selected, setSelected] = useState<number[]>([]);
  const [showEmployeeEdit, setShowEmployeeEdit] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const { showToast } = useAuth();
  const [userId, setUserId] = useState<number>(0);
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [selectedEmployeeData, setselectedEmployeeData] = useState([]);
  const regex = /\(([^)]+)\)/;
  const formatFieldName = (fieldName: string): string => {
    return fieldName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase());
  };

  useEffect(() => {
    useViewEmployeeListMutation.mutate();
    useProjectViewListMutation.mutate();
  }, []);

  useEffect(() => {
    if (useViewEmployeeListMutation.data) {
      setRows(useViewEmployeeListMutation.data);
    } else if (error) {
      showToast(error, "danger");
    }

    if (useUpdateEmployeeMutation.data) {
      showToast("Employee updated successfully", "success");
      setShowEmployeeEdit(false);
      useUpdateEmployeeMutation.reset();
      useViewEmployeeListMutation.mutate();
    } else if (error) {
      const errorMsg = regex.exec(error);
      errorMsg &&
        showToast(`${formatFieldName(errorMsg[1])} already exist`, "danger");
    }

    if (useDeleteEmployeeMutation.data) {
      useDeleteEmployeeMutation.reset();
      setDeleteModal(false);
      showToast(`User ${userId} deleted successfully`, "success");
      useViewEmployeeListMutation.mutate();
    } else if (error) {
      showToast(error, "danger");
    }
  }, [
    useViewEmployeeListMutation.data,
    useDeleteEmployeeMutation.data,
    useUpdateEmployeeMutation.data,
    error,
  ]);

  const convertDate = (dateStr: string): string => {
    const date = new Date(dateStr);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(",", "");

    return formattedDate;
  };

  const handleCheckboxChange = (columnName: string) => {
    setVisibleColumns((prevColumns) =>
      prevColumns?.map((column) =>
        column.name === columnName
          ? { ...column, checked: !column.checked }
          : column
      )
    );
  };

  const renderFilters = () => (
    <React.Fragment>
      {/* ----------------columns-------------- */}
      <Box>
        <FormLabel>Columns</FormLabel>
        <Select size="sm" placeholder="All">
          {visibleColumns?.map((column) => (
            <Option key={column.name} value={column.name}>
              <Checkbox
                label={column.name}
                checked={column.checked}
                onChange={() => handleCheckboxChange(column.name)}
              />
            </Option>
          ))}
        </Select>
      </Box>
    </React.Fragment>
  );

  const handleCellCopy = (content: string | number) => {
    navigator.clipboard
      .writeText(String(content))
      .then(() => {
        showToast("Copied to clipboard!", "success");
      })
      .catch(() => {
        showToast("Failed to copy!", "danger");
      });
  };

  const handleSearchBtn = (event: any) => {
    const { value } = event.target;
    if (value.length > 0) {
      setRows((prvData) =>
        prvData.filter(
          (item) =>
            item.name?.toLowerCase().includes(value.toLowerCase()) ||
            item.email?.toLowerCase().includes(value.toLowerCase()) ||
            item.designation?.toLowerCase().includes(value.toLowerCase()) ||
            item.username?.toLowerCase().includes(value.toLowerCase()) ||
            item.phone?.includes(value)
        )
      );
    } else {
      setRows(useViewEmployeeListMutation.data);
    }
  };

  const handleEditBtn = (usrData: any) => {
    setShowEmployeeEdit(true);
    setselectedEmployeeData(usrData);
  };

  const handleDeleteUser = (id: number) => {
    setDeleteModal(true);
    setUserId(id);
  };

  const handleDeleteConfirmUser = () => {
    if (userId) {
      useDeleteEmployeeMutation.mutate(userId);
    }
  };

  const renderCellContent = (content: string | number, maxWidth: string) => (
    <Tooltip title={content} placement="top">
      <Typography>
        <span
          style={{
            maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            cursor: "copy",
          }}
          onClick={() => handleCellCopy(content)}
        >
          {content}
        </span>
      </Typography>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        {/* ------------search------------ */}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for employee list</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            onChange={handleSearchBtn}
            sx={{ width: { xs: "100%", md: "40%", sm: "100%" } }}
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              {visibleColumns?.map((item, index) => (
                <th
                  key={index}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                  style={{
                    display: `${item.checked ? "table-cell" : "block"}`,
                    width: item.width,
                    padding: "12px 6px",
                  }}
                >
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                </th>
              ))}
              <th
                style={{
                  width: 100,
                  padding: "12px 6px",
                  position: "sticky",
                  right: "50px",
                  color: "#0b6bcb",
                  backgroundColor: mode === "light" ? "#f0f4f8" : "#171a1c",
                }}
              >
                Edit
              </th>
              <th
                style={{
                  width: 80,
                  padding: "12px 6px",
                  position: "sticky",
                  color: "#0b6bcb",
                  backgroundColor: mode === "light" ? "#f0f4f8" : "#171a1c",
                  right: "0",
                }}
              >
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {useViewEmployeeListMutation.isLoading ? (
              <LoaderBox message="Loading" />
            ) : (
              stableSort(rows, getComparator(order, "id")).map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? "primary" : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId) => itemId !== row.id)
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                  {visibleColumns[0].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.empoyeeId, "60")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[1].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.name, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[2].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.email, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[3].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(
                          useProjectViewListMutation?.data?.find(
                            (item: any) => item.id === row.defaultProjectId
                          )?.name,
                          "140"
                        )}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[4].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.password, "140")}
                      </Typography>
                    </td>
                  )}

                  {visibleColumns[5].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.phone, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[6].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.address, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[7].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.designation, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[8].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.emiratesId, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[9].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.basicSalary, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[10].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(convertDate(row.dob), "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[11].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(
                          convertDate(row.emiratesExpiryDate),
                          "140"
                        )}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[12].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(convertDate(row.joiningDate), "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[13].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.overtimePriceHourly, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[14].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.passportNo, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[15].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(
                          convertDate(row.passportExpiryDate),
                          "140"
                        )}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[16].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(
                          convertDate(row.salaryCertDate),
                          "140"
                        )}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[17].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(
                          convertDate(row.salaryCertExpiry),
                          "140"
                        )}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[18].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.username, "140")}
                      </Typography>
                    </td>
                  )}
                  <td
                    style={{
                      position: "sticky",
                      right: "50px",
                      backgroundColor: mode === "light" ? "#fbfcfe" : "#0b0d0e",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <Link level="body-xs" component="button">
                          <CreateRoundedIcon
                            sx={{
                              color: "coral",
                              fontSize: "20px",
                              borderRadius: "20px",
                              "&:hover": {
                                padding: "2px",
                                backgroundColor: "coral",
                                color: "white",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleEditBtn(row)}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  </td>
                  <td
                    style={{
                      position: "sticky",
                      right: "0",
                      backgroundColor: mode === "light" ? "#fbfcfe" : "#0b0d0e",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Tooltip title="Delete" placement="top">
                        <Link level="body-xs" component="button">
                          <DeleteForever
                            sx={{
                              color: "red",
                              fontSize: "20px",
                              borderRadius: "20px",
                              "&:hover": {
                                padding: "1px",
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleDeleteUser(row.id)}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Sheet>
      {/* -------------pagination-------------- */}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: "flex",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box
          sx={{
            flex: 1,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        />
        {["1", "2", "3", "…"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box
          sx={{
            flex: 1,
          }}
        />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
      {/* -----------------Delete Employee----------- */}
      <DeleteModalBox
        open={deleteModal}
        setOpen={setDeleteModal}
        message={"Are you sure you want to delete user?"}
        handleDeleteCnfrm={handleDeleteConfirmUser}
      />
      {/* -----------------Edit Employee----------- */}
      <Modal open={showEmployeeEdit} onClose={() => setShowEmployeeEdit(false)}>
        <ModalDialog
          sx={{
            marginLeft: { sm: "5%", xs: "0%" },
            marginTop: { sm: "0%", xs: "5%" },
          }}
        >
          <DialogTitle>{"Edit Employee"}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowEmployeeEdit(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <UpdateEmployeForm data={selectedEmployeeData} />
        </ModalDialog>
      </Modal>
    </React.Fragment>
  );
}
