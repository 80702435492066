import * as React from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import Box from "@mui/joy/Box";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Link from "@mui/joy/Link";
import Typography from "@mui/joy/Typography";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import Header from "../../components/globalCards/Header";
import Sidebar from "../../components/globalCards/Sidebar";
import OperationalExpensesCard from "../../components/PurchaseCards/OperationalExpensesCard";
import { Option, Select } from "@mui/joy";
import CompanyExpensesCard from "../../components/PurchaseCards/CompanyExpensesCard";

const ExpensePage: React.FC = () => {
  const [activeComponent, setActiveComponent] =
    React.useState<string>("ExpensePage");
  const [content, setContent] = React.useState("operationalExpenses");
  const handleStatusChange = (value: string | null) => {
    if (value !== null) {
      setContent(value);
    }
  };
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Header />
        <Sidebar
          activeComponent={activeComponent}
          setActiveComponent={setActiveComponent}
        />
        <Box
          component="main"
          className="MainContent"
          sx={{
            px: { xs: 2, md: 6 },
            pt: {
              xs: "calc(12px + var(--Header-height))",
              sm: "calc(12px + var(--Header-height))",
              md: 3,
            },
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon />}
              sx={{ pl: 0 }}
            >
              <Link
                underline="none"
                color="neutral"
                href="/dashboard"
                aria-label="Home"
              >
                <HomeRoundedIcon />
              </Link>
              <Typography color="primary" fontWeight={500} fontSize={12}>
                Expenses
              </Typography>
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              display: "flex",
              mb: 1,
              gap: 1,
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "start", sm: "center" },
              flexWrap: "wrap",
              justifyContent: "space-between",
            }}
          >
            <Select
              value={content}
              onChange={(event, value) => handleStatusChange(value)}
              indicator={
                <KeyboardArrowDownIcon
                  sx={{ fontSize: 28, marginTop: "4px" }}
                />
              }
              sx={{
                background: "transparent",
                borderRadius: "20px",
                boxShadow: "none",
                border: "none",
                IconFontSize: "calc(1em* var(--joy-lineHeight-xs, 1.33334))",
                margin: "var(--Typography-margin, 0px)",
                fontFamily:
                  'var(--joy-fontFamily-display, "Inter", var(--joy-fontFamily-fallback, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"))',
                fontWeight: "var(--joy-fontWeight-xl, 700)",
                fontSize:
                  "var(--Typography-fontSize, var(--joy-fontSize-xl3, 1.875rem))",
                lineHeight: "var(--joy-lineHeight-xs, 1.33334)",
                letterSpacing: "-0.025em",
                color:
                  "var(--joy-palette-text-primary, var(--joy-palette-neutral-800, #171A1C))",
                paddingLeft: "0",
                "&:hover": {
                  background: "transparent",
                },
              }}
              name="attendanceStatus"
            >
              <Option value="operationalExpenses">Operational Expenses</Option>
              <Option value="companyexpenses">Company Expenses</Option>
            </Select>
          </Box>
          {content === "operationalExpenses" && <OperationalExpensesCard />}
          {content === "companyexpenses" && <CompanyExpensesCard />}
        </Box>
      </Box>
    </CssVarsProvider>
  );
};

export default ExpensePage;
