import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
  Tooltip,
} from "@mui/joy";
import CloseIcon from "@mui/icons-material/Close";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import { DeleteModalBoxProps, EditModalProps } from "../../types";

export function EditFormModal({
  open,
  setOpen,
  title,
  email,
  phone,
  password,
  statusCard,
  startDateTimeCard,
  endDateTimeCard,
  overTimeStartCard,
  overTimeEndCard,
  startDateTime,
  endDateTime,
  overTimeStart,
  projectName,
  projectNameCard,
  overTimeEnd,
  attendanceStatus,
  holidayDateTime,
  holidayRemarkCard,
  companyNameCard,
  companyName,
  isLoading,
  selectedList,
  selectorTitle,
  holidayRemark,
  remark,
  remarkCard,
  holidayStatus,
  companyNameStatusCard,
  companyNameSelected,
  projectNameSelected,
  projectNameStatusCard,
  projectNameList,
  setCompanyNameSelected,
  setSelectedName,
  selectorCard,
  selectedName,
  setProjectNameSelected,
  companyNameList,
  startDate,
  startDateCard,
  endDate,
  endDateCard,
  setSelectedStatus,
  setCity,
  cityListCard,
  amountCard,
  address,
  city,
  country,
  trnNo,
  addressCard,
  cityCard,
  countryCard,
  trnCard,
  amount,
  nameTitle,
  onSubmit,
}: EditModalProps) {
  const handleStatusChange = (
    event: React.SyntheticEvent | null,
    value: string | null
  ) => {
    if (value && setSelectedStatus) {
      setSelectedStatus(value);
    }
  };
  const handleCityInput = (e: any) => {
    if (e.target.value && setCity) setCity(e.target.value);
  };

  const handleCityChange = (
    event: React.SyntheticEvent | null,
    value: string | null
  ) => {
    if (value && setCity) {
      setCity(value);
    }
  };
  const handleCompanyStatusChange = (
    event: React.SyntheticEvent | null,
    value: string | null
  ) => {
    if (value && setCompanyNameSelected) {
      setCompanyNameSelected(value);
    }
  };

  const handleSelectedStatusChange = (
    event: React.SyntheticEvent | null,
    value: string | null
  ) => {
    if (value && setSelectedName) {
      setSelectedName(value);
    }
  };

  const handleProjectNameChange = (
    event: React.SyntheticEvent | null,

    value: string | null
  ) => {
    if (value && setProjectNameSelected) {
      setProjectNameSelected(value);
    }
  };

  const convertToHourTime = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog
        sx={{
          marginLeft: { sm: "5%", xs: "0%" },
          marginTop: { sm: "0%", xs: "5%" },
        }}
      >
        <Tooltip title={title} placement="top">
          <DialogTitle
            sx={{
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
            }}
          >
            {title}
          </DialogTitle>
        </Tooltip>
        <IconButton
          aria-label="close"
          onClick={() => setOpen(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <form onSubmit={onSubmit}>
          <Stack>
            {email && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Email</FormLabel>
                <Input
                  autoFocus
                  required
                  defaultValue={email ? email : ""}
                  name="email"
                  type="email"
                />
              </FormControl>
            )}
            {phone && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Phone</FormLabel>
                <Input
                  required
                  defaultValue={phone ? phone : ""}
                  name="phone"
                  type="number"
                />
              </FormControl>
            )}
            {password && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Password</FormLabel>
                <Input
                  name="password"
                  required={password !== "showPasswordField" ? true : false}
                  defaultValue={
                    password !== "showPasswordField" ? password : ""
                  }
                  type="password"
                />
              </FormControl>
            )}
            {startDateCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Select Start Date</FormLabel>
                <Input
                  name="startDate"
                  defaultValue={
                    startDate
                      ? new Date(startDate).toISOString().split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  type="date"
                />
              </FormControl>
            )}
            {endDateCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Select End Date</FormLabel>
                <Input
                  name="endDate"
                  defaultValue={
                    endDate
                      ? new Date(endDate).toISOString().split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  type="date"
                />
              </FormControl>
            )}
            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {startDateTimeCard && (
                <FormControl sx={{ margin: "5px 0px", width: "100%" }}>
                  <FormLabel>Check-in Time</FormLabel>
                  <Input
                    name="startDateTime"
                    defaultValue={
                      startDateTime && convertToHourTime(startDateTime)
                    }
                    type="time"
                  />
                </FormControl>
              )}
              {endDateTimeCard && (
                <FormControl sx={{ marginLeft: "20px", width: "100%" }}>
                  <FormLabel>Check-out Time</FormLabel>
                  <Input
                    name="endDateTime"
                    defaultValue={endDateTime && convertToHourTime(endDateTime)}
                    type="time"
                    slotProps={{
                      input: {
                        min: startDateTime && convertToHourTime(startDateTime),
                      },
                    }}
                  />
                </FormControl>
              )}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexFlow: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {overTimeStartCard && (
                <FormControl sx={{ margin: "5px 0px", width: "100%" }}>
                  <FormLabel>Overtime Check-in</FormLabel>
                  <Input
                    name="overTimeStart"
                    defaultValue={
                      overTimeStart && convertToHourTime(overTimeStart)
                    }
                    type="time"
                  />
                </FormControl>
              )}

              {overTimeEndCard && (
                <FormControl
                  sx={{ marginLeft: "20px", width: "100%", whiteSpace: "pre" }}
                >
                  <FormLabel>Overtime Check-out</FormLabel>
                  <Input
                    name="overTimeEnd"
                    defaultValue={overTimeEnd && convertToHourTime(overTimeEnd)}
                    slotProps={{
                      input: {
                        min: overTimeStart && convertToHourTime(overTimeStart),
                      },
                    }}
                    type="time"
                  />
                </FormControl>
              )}
            </Box>

            {remarkCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Remark</FormLabel>
                <Input
                  defaultValue={remark && remark}
                  name="remark"
                  type="text"
                />
              </FormControl>
            )}
            {selectorCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>{selectorTitle}</FormLabel>
                <Select
                  value={selectedName}
                  placeholder={selectedName}
                  onChange={handleSelectedStatusChange}
                  size="sm"
                  name="selectedName"
                >
                  {selectedList?.map((item, index) => (
                    <Option key={index} value={item.name}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            {statusCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Status</FormLabel>
                <Select
                  defaultValue={attendanceStatus ? attendanceStatus : "PRESENT"}
                  value={attendanceStatus}
                  onChange={handleStatusChange}
                  size="sm"
                  name="attendanceStatus"
                >
                  <Option value="PRESENT">PRESENT</Option>
                  <Option value="ABSENT">ABSENT</Option>
                  <Option value="LEAVE">LEAVE</Option>
                  <Option value="HALF_DAY">HALF_DAY</Option>
                </Select>
              </FormControl>
            )}

            {holidayDateTime && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Holiday Date</FormLabel>
                <Input
                  name="holidayDateTime"
                  defaultValue={
                    holidayDateTime
                      ? new Date(holidayDateTime).toISOString().split("T")[0]
                      : new Date().toISOString().split("T")[0]
                  }
                  type="date"
                />
              </FormControl>
            )}

            {holidayRemarkCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Reamrk</FormLabel>
                <Input
                  defaultValue={holidayRemark && holidayRemark}
                  name="holidayRemark"
                  type="text"
                />
              </FormControl>
            )}
            {holidayStatus && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Status</FormLabel>
                <Select
                  defaultValue={holidayStatus ? holidayStatus : "WORKING"}
                  value={holidayStatus}
                  onChange={handleStatusChange}
                  size="sm"
                  name="holidayStatus"
                >
                  <Option value="HOLIDAY">HOLIDAY</Option>
                  <Option value="WORKING">WORKING</Option>
                </Select>
              </FormControl>
            )}
            {companyNameCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>{nameTitle}</FormLabel>
                <Input
                  autoFocus
                  defaultValue={companyName ? companyName : ""}
                  name="companyName"
                  type="text"
                />
              </FormControl>
            )}
            {projectNameCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Project Name</FormLabel>
                <Input
                  autoFocus
                  defaultValue={projectName ? projectName : ""}
                  name="projectName"
                  type="text"
                />
              </FormControl>
            )}
            {companyNameStatusCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Company Name</FormLabel>
                <Select
                  value={companyNameSelected}
                  placeholder={companyNameSelected}
                  onChange={handleCompanyStatusChange}
                  size="sm"
                  name="companyNameSelected"
                >
                  {companyNameList?.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            {addressCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Address</FormLabel>
                <Input
                  autoFocus
                  defaultValue={address ? address : ""}
                  name="address"
                  type="text"
                />
              </FormControl>
            )}
            {cityCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>City</FormLabel>
                <Input
                  autoFocus
                  onChange={(e) => handleCityInput(e)}
                  defaultValue={city ? city : ""}
                  name="city"
                  type="text"
                />
              </FormControl>
            )}

            {cityListCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>City</FormLabel>
                <Select
                  defaultValue={city ? city : "Dubai"}
                  value={city}
                  onChange={handleCityChange}
                  size="sm"
                  name="city"
                >
                  <Option value="Emirates">Emirates</Option>
                  <Option value="Abu Dhabi">Abu Dhabi</Option>
                  <Option value="Dubai">Dubai</Option>
                  <Option value="Sharjah">Sharjah</Option>
                  <Option value="Ras Al Khaima">Ras Al Khaima</Option>
                  <Option value="Ajman">Ajman</Option>
                  <Option value="Fujaria">Fujaria</Option>
                  <Option value="Al Ain">Al Ain</Option>
                </Select>
              </FormControl>
            )}
            {countryCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Country</FormLabel>
                <Input
                  autoFocus
                  defaultValue={country ? country : ""}
                  name="country"
                  type="text"
                />
              </FormControl>
            )}

            {trnCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>TRN No.</FormLabel>
                <Input
                  autoFocus
                  defaultValue={trnNo ? trnNo : ""}
                  name="trnNo"
                  type="text"
                />
              </FormControl>
            )}
            {amountCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Amount</FormLabel>
                <Input
                  autoFocus
                  defaultValue={amount ? amount : ""}
                  name="amount"
                  type="number"
                />
              </FormControl>
            )}
            {projectNameStatusCard && (
              <FormControl sx={{ margin: "5px 0px" }}>
                <FormLabel>Project List</FormLabel>
                <Select
                  value={projectNameSelected}
                  placeholder={projectNameSelected}
                  onChange={handleProjectNameChange}
                  size="sm"
                  name="projectNameSelected"
                >
                  {projectNameList?.map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            <Button
              type="submit"
              sx={{ marginTop: "14px" }}
              loading={isLoading}
              loadingPosition="end"
            >
              Save
            </Button>
          </Stack>
        </form>
      </ModalDialog>
    </Modal>
  );
}

export function DeleteModalBox({
  open,
  setOpen,
  message,
  handleDeleteCnfrm,
}: DeleteModalBoxProps) {
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          <WarningRoundedIcon />
          Confirmation
        </DialogTitle>
        <Divider />
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button variant="solid" color="danger" onClick={handleDeleteCnfrm}>
            Delete
          </Button>
          <Button
            variant="plain"
            color="neutral"
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
}
