import {
  Box,
  Button,
  DialogTitle,
  IconButton,
  Modal,
  ModalDialog,
  Tooltip,
} from "@mui/joy";
import { useEffect } from "react";
import { CalendarBoxProps } from "../../types";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBefore from "@mui/icons-material/NavigateBefore";
import NavigateNext from "@mui/icons-material/NavigateNext";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const Calendar = ({
  open,
  data,
  currentDate,
  setCurrentDate,
  handleEditBtn,
  title,
  userId,
  handleCloseViewCalendar,
  projectNameList,
}: CalendarBoxProps) => {
  const getStartDay = () => {
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    ).getDay();
  };
  const convertToHourTime = (dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const amPm = Number(hours) >= 12 ? "pm" : "am";
    let hour = Number(hours) % 12 || 12;
    const formattedHours = String(hour).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  };
  const getDaysInMonth = () => {
    return new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
  };

  // Function to navigate to the previous month
  const handlePreviousMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentDate(
      new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1)
    );
  };
  const { useDateVisAttendaceEmployee, error } = useApi();
  const { showToast } = useAuth();
  const getDateEmployeeData = (date: string) => {
    const getEmployeInfo = {
      id: userId,
      startDate: date,
      endDate: date,
    };
    if (getEmployeInfo) {
      useDateVisAttendaceEmployee.mutate(getEmployeInfo);
    }
  };
  useEffect(() => {
    if (useDateVisAttendaceEmployee.data) {
      handleEditBtn(useDateVisAttendaceEmployee.data[0]);
    } else if (error) {
      showToast(error, "danger");
    }
  }, [useDateVisAttendaceEmployee.data]);

  const renderDays = () => {
    const startDay = getStartDay();
    const daysInMonth = getDaysInMonth();
    const days = [];
    for (let i = 0; i < startDay; i++) {
      days.push(<Box className="day-cell empty" key={`empty-${i}`}></Box>);
    }
    const sortData = data.sort(
      (a: any, b: any) =>
        new Date(a.date).getTime() - new Date(b.date).getTime()
    );
    days.push(
      sortData?.map((item, index) => (
        <Box
          className="day-cell"
          key={index}
          onClick={() => getDateEmployeeData(item.date)}
          sx={{
            borderTop: "4px solid",
            borderRadius: "4px",
            height: "140px",
            pointerEvents: item?.Attendance[0]?.state ? "auto" : "none",
            borderTopColor:
              item?.Attendance[0]?.state === "PRESENT"
                ? "#6ABA7B"
                : item?.Attendance[0]?.state === "LEAVE"
                ? "#F3AE5F"
                : item?.Attendance[0]?.state === "ABSENT"
                ? "#F29395"
                : item?.Attendance[0]?.state === "HALF_DAY"
                ? "#C074F8"
                : "#9a6170",
            background:
              item?.Attendance[0]?.state === "PRESENT"
                ? "#E3F3DB"
                : item?.Attendance[0]?.state === "LEAVE"
                ? "#FEF4E5"
                : item?.Attendance[0]?.state === "HALF_DAY"
                ? "#F1E1FD"
                : item?.Attendance[0]?.state === "ABSENT"
                ? "#FBE6E7"
                : "#f0eded",
          }}
        >
          <div className="date">
            <span className="date-day">
              {new Date(item.date).toISOString().slice(8, 10)}
            </span>
            <span
              className="date-status"
              style={{
                color: item.status === "WORKING" ? "#2eb42e" : "#4097ef",
                textTransform: "capitalize",
              }}
            >
              {item.status}
            </span>
          </div>
          <div>
            <div
              className="status"
              style={{
                color:
                  item?.Attendance[0]?.state === "PRESENT"
                    ? "#6ABA7B"
                    : item?.Attendance[0]?.state === "LEAVE"
                    ? "#F3AE5F"
                    : item?.Attendance[0]?.state === "ABSENT"
                    ? "#F29395"
                    : item?.Attendance[0]?.state === "HALF_DAY"
                    ? "#C074F8"
                    : "#9a6170",
                borderLeftColor:
                  item?.Attendance[0]?.state === "PRESENT"
                    ? "#6ABA7B"
                    : item?.Attendance[0]?.state === "LEAVE"
                    ? "#F3AE5F"
                    : item?.Attendance[0]?.state === "ABSENT"
                    ? "#F29395"
                    : item?.Attendance[0]?.state === "HALF_DAY"
                    ? "#C074F8"
                    : "#9C6E7A",
              }}
            >
              {item?.Attendance[0]?.state ? item?.Attendance[0]?.state : "None"}
            </div>
            <div className="project-name">
              <Tooltip
                title={
                  item.Attendance[0]?.projectId
                    ? projectNameList?.find(
                        (dataItem: any) =>
                          dataItem.id === item.Attendance[0]?.projectId
                      )?.name
                    : projectNameList?.find(
                        (dataItem: any) => dataItem.id === item.defaultProjectId
                      )?.name
                }
                placement="top"
              >
                <span
                  style={{
                    maxWidth: "120px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                  }}
                >
                  {item.Attendance[0]?.projectId
                    ? projectNameList?.find(
                        (dataItem: any) =>
                          dataItem.id === item.Attendance[0]?.projectId
                      )?.name
                    : projectNameList?.find(
                        (dataItem: any) => dataItem.id === item.defaultProjectId
                      )?.name}
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="time">
            {`${
              item?.Attendance[0]?.checkInTime
                ? convertToHourTime(item?.Attendance[0]?.checkInTime)
                : "00:00"
            }
            to
            ${
              item?.Attendance[0]?.checkInTime
                ? convertToHourTime(item?.Attendance[0]?.checkOutTime)
                : "00:00"
            }`}
          </div>
        </Box>
      ))
    );

    return days;
  };

  return (
    <Modal
      open={open}
      onClose={() => {
        handleCloseViewCalendar();
      }}
    >
      <ModalDialog
        sx={{
          marginLeft: { xs: "8%", ms: "10%" },
          width: { xs: "80%", ms: "80%" },
          backgroundColor: "white",
        }}
      >
        <Box className="calendar-container">
          <Box className="calendar-header">
            <Tooltip title={title} placement="top">
              <Box
                className="title"
                sx={{
                  maxWidth: "350px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "block",
                }}
              >
                {title} - {currentDate.getFullYear()}
              </Box>
            </Tooltip>
            <Box className="navigation">
              <Button
                color="primary"
                className="nav-button"
                onClick={handlePreviousMonth}
              >
                <NavigateBefore />
              </Button>
              <span className="month">
                {currentDate.toLocaleString("default", { month: "long" })}
              </span>
              <Button
                color="primary"
                className="nav-button"
                onClick={handleNextMonth}
              >
                <NavigateNext />
              </Button>
              <IconButton aria-label="close" onClick={handleCloseViewCalendar}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>
          <Box className="calendar-grid">
            {daysOfWeek.map((day, index) => (
              <Box className="day-header" key={index}>
                {day}
              </Box>
            ))}
            {renderDays()}
          </Box>
        </Box>
      </ModalDialog>
    </Modal>
  );
};

export default Calendar;
