import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import { CreateUserFormElement, ViewUserDto } from "../../types";
import { DialogContent, DialogTitle, Stack, Tooltip } from "@mui/joy";
import { DeleteModalBox, EditFormModal } from "../globalCards/ModalBox";
import { LoaderBox, NotfoundBox } from "../globalCards/LoaderBox";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function UsersViewCard() {
  const {
    useUpdateUserMutation,
    useGetUserListResponseMutation,
    useUpdatePermissionMutation,
    getUserListResponse,
    updateUserResponse,
    useDeleteUserMutation,
    deletedUserResponse,
    error,
  } = useApi();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = React.useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [visibleColumns, setVisibleColumns] = useState([
    {
      checked: true,
      width: 60,
      name: "ID",
    },
    {
      checked: true,
      width: 140,
      name: "Email",
    },
    {
      checked: true,
      width: 140,
      name: "Phone",
    },
    {
      checked: true,
      width: 140,
      name: "Permissions",
    },
  ]);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [rows, setRows] = useState<ViewUserDto[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [selected, setSelected] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const { showToast } = useAuth();

  useEffect(() => {
    if (useGetUserListResponseMutation) {
      useGetUserListResponseMutation.mutate();
    }
  }, []);

  useEffect(() => {
    if (getUserListResponse) {
      const filterUserData = getUserListResponse.filter(
        (item: any) => item.email !== "admin@whitecoreuae.com"
      );
      setRows(filterUserData);
    } else if (error) {
      showToast(error, "danger");
    }
    if (updateUserResponse) {
      useUpdateUserMutation.reset();
      setShowModal(false);
      showToast(`User ${email} updated successfully`, "success");
      useGetUserListResponseMutation.mutate();
    } else if (error) {
      showToast(error, "danger");
    }
    if (useUpdatePermissionMutation.data) {
      useUpdatePermissionMutation.reset();
      useGetUserListResponseMutation.mutate();
      showToast("User permission updated", "success");
    } else if (error) {
      showToast(error, "danger");
    }
    if (deletedUserResponse) {
      useDeleteUserMutation.reset();
      setDeleteModal(false);
      showToast(`User ${userId} deleted successfully`, "success");
      useGetUserListResponseMutation.mutate();
    } else if (error) {
      showToast(error, "danger");
    }
  }, [
    getUserListResponse,
    updateUserResponse,
    deletedUserResponse,
    useUpdatePermissionMutation.data,
    error,
  ]);

  const handleCheckboxChange = (columnName: string) => {
    setVisibleColumns((prevColumns) =>
      prevColumns?.map((column) =>
        column.name === columnName
          ? { ...column, checked: !column.checked }
          : column
      )
    );
  };

  const renderFilters = () => (
    <React.Fragment>
      {/* ----------------columns-------------- */}
      <Box>
        <FormLabel>Columns</FormLabel>
        <Select size="sm" placeholder="All">
          {visibleColumns?.map((column) => (
            <Option key={column.name} value={column.name}>
              <Checkbox
                label={column.name}
                checked={column.checked}
                onChange={() => handleCheckboxChange(column.name)}
              />
            </Option>
          ))}
        </Select>
      </Box>
    </React.Fragment>
  );

  const handleCellCopy = (content: string | number) => {
    navigator.clipboard
      .writeText(String(content))
      .then(() => {
        showToast("Copied to clipboard!", "success");
      })
      .catch(() => {
        showToast("Failed to copy!", "danger");
      });
  };

  const renderCellContent = (content: string | number, maxWidth: string) => (
    <Tooltip title={content} placement="top">
      <Typography>
        <span
          style={{
            maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
            cursor: "copy",
          }}
          onClick={() => handleCellCopy(content)}
        >
          {content}
        </span>
      </Typography>
    </Tooltip>
  );

  const removeCountryCode = (phoneNumber: string): string => {
    return phoneNumber.replace(/^\+971\s*/, "").replace(/\s+/g, "");
  };

  const handleEditBtn = (usrData: any) => {
    setShowModal(true);
    setUserId(usrData.id);
    setEmail(usrData.email);
    setPhone(removeCountryCode(usrData.phone));
  };

  const handleDeleteUser = (id: number) => {
    setDeleteModal(true);
    setUserId(id);
  };

  const handleDeleteConfirmUser = () => {
    if (userId) {
      useDeleteUserMutation.mutate(userId);
    }
  };

  const convertToFormattedUAEPhoneNumber = (phoneNumber: string): string => {
    let sanitizedPhone = phoneNumber.replace(/\D/g, "");
    if (sanitizedPhone.startsWith("0")) {
      sanitizedPhone = sanitizedPhone.slice(1);
    }
    const formattedUAEPhoneNumber = `+971 ${sanitizedPhone.slice(
      0,
      2
    )} ${sanitizedPhone.slice(2)}`;

    return formattedUAEPhoneNumber;
  };

  const handleUpdateUser = (event: React.FormEvent<CreateUserFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    if (!formElements.email.value || !formElements.phone.value) {
      showToast("Please fill in all fields.", "warning");
      return;
    }

    const updatedData: { [key: string]: any } = {};

    if (formElements.email.value !== email) {
      updatedData.email = formElements.email.value;
    }

    if (formElements.phone.value !== phone) {
      updatedData.phone = convertToFormattedUAEPhoneNumber(
        formElements.phone.value
      );
    }

    if (formElements.password.value !== "") {
      updatedData.password = formElements.password.value;
    }

    if (Object.keys(updatedData).length > 0) {
      useUpdateUserMutation.mutate({
        id: userId,
        newData: updatedData,
      });
    }
  };

  const handlePermissionCheckbox = (rowData: any, key: any, value: any) => {
    if (key) {
      const data = { ...rowData };
      data[key] = value;
      delete data.id;
      delete data.userId;
      data !== undefined &&
        useUpdatePermissionMutation.mutate({
          id: rowData.userId,
          newData: { [key]: value },
        });
    }
  };

  const renderPermissionDropDown = (rowData: any, maxWidth: string) => {
    const filterData = rowData;
    return (
      <Select
        size="sm"
        sx={{ maxWidth: maxWidth }}
        placeholder="Permission List"
        slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
      >
        {Object.entries(filterData).map(
          ([key, value]) =>
            key !== "id" &&
            key !== "userId" && (
              <Option key={key} value={key}>
                <Checkbox
                  label={key}
                  name={key}
                  checked={Boolean(value)}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    handlePermissionCheckbox(rowData, key, checked);
                  }}
                />
              </Option>
            )
        )}
      </Select>
    );
  };

  const handleSearchBtn = (event: any) => {
    const { value } = event.target;
    if (value.length > 0) {
      setRows((prvData) =>
        prvData.filter(
          (item) =>
            item.email?.toLowerCase().includes(value.toLowerCase()) ||
            item.phone?.includes(value)
        )
      );
    } else {
      setRows(getUserListResponse);
    }
  };

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        {/* ------------search------------ */}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for users list</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            onChange={handleSearchBtn}
            sx={{ width: { xs: "100%", md: "40%", sm: "100%" } }}
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              {visibleColumns?.map((item, index) => (
                <th
                  key={index}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                  style={{
                    display: `${item.checked ? "table-cell" : "block"}`,
                    width: item.width,
                    padding: "12px 6px",
                  }}
                >
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                </th>
              ))}
              <th style={{ width: 80, padding: "12px 6px", color: "#0b6bcb" }}>
                Edit
              </th>
              <th style={{ width: 80, padding: "12px 6px", color: "#0b6bcb" }}>
                Delete
              </th>
            </tr>
          </thead>
          <tbody>
            {useGetUserListResponseMutation.isLoading ? (
              <LoaderBox message="Loading" />
            ) : rows?.length === 0 ? (
              <NotfoundBox />
            ) : (
              stableSort(rows, getComparator(order, "id")).map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? "primary" : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId) => itemId !== row.id)
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                  {visibleColumns[0].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.id, "60")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[1].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.email, "140")}
                      </Typography>
                    </td>
                  )}

                  {visibleColumns[2].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.phone, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[3].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderPermissionDropDown(row.Permissions[0], "140")}
                      </Typography>
                    </td>
                  )}
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Edit" placement="top">
                        <Link level="body-xs" component="button">
                          <CreateRoundedIcon
                            sx={{
                              color: "coral",
                              fontSize: "20px",
                              borderRadius: "20px",
                              "&:hover": {
                                padding: "2px",
                                backgroundColor: "coral",
                                color: "white",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleEditBtn(row)}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  </td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <Tooltip title="Delete" placement="top">
                        <Link level="body-xs" component="button">
                          <DeleteForever
                            sx={{
                              color: "red",
                              fontSize: "20px",
                              borderRadius: "20px",
                              "&:hover": {
                                padding: "1px",
                                backgroundColor: "red",
                                color: "white",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => handleDeleteUser(row.id)}
                          />
                        </Link>
                      </Tooltip>
                    </Box>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Sheet>
      {/* -----------------Edit User-------------- */}
      <EditFormModal
        open={showModal}
        setOpen={setShowModal}
        title={"Edit User Details"}
        email={email}
        phone={phone}
        password="showPasswordField"
        onSubmit={handleUpdateUser}
      />

      {/* ---------------Delete User--------------  */}
      <DeleteModalBox
        open={deleteModal}
        setOpen={setDeleteModal}
        message={"Are you sure you want to delete user?"}
        handleDeleteCnfrm={handleDeleteConfirmUser}
      />
      {/* ---------------pagination--------------- */}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: "flex",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box
          sx={{
            flex: 1,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        />
        {["1", "2", "3", "…"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box
          sx={{
            flex: 1,
          }}
        />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
