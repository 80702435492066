import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import CreateRoundedIcon from "@mui/icons-material/CreateRounded";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import DeleteForever from "@mui/icons-material/DeleteForever";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import {
  CalendarDto,
  CreateUserFormElement,
  ViewUserListDto,
} from "../../types";
import { Tooltip } from "@mui/joy";
import Calendar from "../EmployeeCards/Calendar";
import { EditFormModal } from "../globalCards/ModalBox";
import ExportToExcel from "../globalCards/ExportToExcel";
import { LoaderBox } from "../globalCards/LoaderBox";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function AttendanceCard() {
  const validStates = ["PRESENT", "ABSENT", "LEAVE", "HALF_DAY"];
  const {
    useEmployeeAttendanceMutation,
    useBulkAttendanceMutation,
    useUpdateAttendanceMutation,
    useDateVisAttendaceEmployee,
    useUpdateEmployeeMutation,
    useDownloadAttendanceMutation,
    useHolidayCalendarViewMutation,
    useAttendaceEmployeeListMutation,
    useProjectViewListMutation,
    error,
  } = useApi();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [userId, setUserId] = useState<number>(0);
  const [attendaceId, setAttendaceId] = useState<number>();
  const [userName, setUserName] = useState<string>("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [visibleColumns, setVisibleColumns] = useState([
    {
      checked: true,
      width: 60,
      name: "ID",
    },
    {
      checked: true,
      width: 140,
      name: "Name",
    },
    {
      checked: true,
      width: 140,
      name: "Project",
    },
    {
      checked: true,
      width: 140,
      name: "Status",
    },
    {
      checked: true,
      width: 140,
      name: "View",
    },
    {
      checked: true,
      width: 140,
      name: "CheckIn",
    },
    {
      checked: true,
      width: 140,
      name: "CheckOut",
    },
    {
      checked: true,
      width: 140,
      name: "Overtime-CheckIn",
    },
    {
      checked: true,
      width: 180,
      name: "Overtime-CheckOut",
    },
  ]);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [overTimeStart, setOverTimeStart] = useState("");
  const [overTimeEnd, setOverTimeEnd] = useState("");
  const [remark, setRemark] = useState("");
  const [attendanceStatus, setAttendanceStatus] = useState("PRESENT");
  const [status, setStatus] = useState("PRESENT");
  const [rows, setRows] = useState<ViewUserListDto[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [selected, setSelected] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [projectNameSelected, setProjectNameSelected] = useState("");
  const [projectName, setProjectName] = useState("");
  const { showToast } = useAuth();
  const [projectNameList, setProjectNameList] = useState<[]>([]);
  const [employeCalendarData, setEmployeCalendarData] = useState<CalendarDto[]>(
    []
  );
  const [viewCalendar, setViewCalendar] = useState<boolean>(false);
  const [timeCardShow, setTimeCardShow] = useState(false);
  const [projectDownloadCard, setProjectDownloadCard] =
    useState<boolean>(false);
  const convertToHourTime = (dateString: string) => {
    const date = new Date(dateString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const amPm = Number(hours) >= 12 ? "pm" : "am";
    let hour = Number(hours) % 12 || 12;
    const formattedHours = String(hour).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes} ${amPm}`;
  };

  const [attendanceData, setAttendanceData] = useState([]);

  const currentDateTime = new Date();
  const [todayDate, setTodayDate] = useState<string>(
    currentDateTime.toISOString()
  );
  const startOfYear = new Date(currentDate.getFullYear(), 0, 1).toISOString();
  const endOfYear = new Date(currentDate.getFullYear() + 1, 0, 0).toISOString();
  const getHolidayData = {
    startDate: startOfYear,
    endDate: endOfYear,
    status: "HOLIDAY",
  };
  const [holidayDates, setHolidayDates] = useState<string[] | undefined>(
    undefined
  );
  useEffect(() => {
    if (todayDate) {
      if (useAttendaceEmployeeListMutation) {
        useAttendaceEmployeeListMutation.mutate(todayDate);
        useProjectViewListMutation.mutate();
        useHolidayCalendarViewMutation.mutate(getHolidayData);
      }
    }
  }, [todayDate]);

  useEffect(() => {
    if (useAttendaceEmployeeListMutation.data) {
      setRows(useAttendaceEmployeeListMutation.data);
    } else if (error) {
      showToast(error, "danger");
    }
    if (useProjectViewListMutation.data) {
      const projectList = useProjectViewListMutation.data?.map(
        (item: any) => item.name
      );
      setProjectNameList(projectList);
    } else if (error) {
      showToast(error, "danger");
    }
    if (useBulkAttendanceMutation.data) {
      showToast("Attendance updated successfully", "success");
      setSelected([]);
      useBulkAttendanceMutation.reset();
      setTimeCardShow(false);
      useAttendaceEmployeeListMutation.mutate(todayDate);
    } else if (error) {
      showToast(error, "danger");
    }
    if (useEmployeeAttendanceMutation.data) {
      setEmployeCalendarData(useEmployeeAttendanceMutation.data);
    } else if (error) {
      showToast(error, "danger");
    }
    if (useUpdateAttendanceMutation.data) {
      showToast("Attendance updated successfully", "success");
      useUpdateAttendanceMutation.reset();
      useDateVisAttendaceEmployee.reset();
      setShowModal(false);
      setAttendanceStatus("PRESENT");
      useAttendaceEmployeeListMutation.mutate(todayDate);
      handleViewCalendarUpdate();
    } else if (error) {
      showToast(error, "danger");
    }
    if (showModal === false) {
      useUpdateAttendanceMutation.reset();
      useDateVisAttendaceEmployee.reset();
    }
    if (useHolidayCalendarViewMutation.data) {
      const holidayDates = useHolidayCalendarViewMutation.data;
      const filterData = holidayDates
        .filter((holiday: { status: string }) => holiday.status === "HOLIDAY")
        .map((holiday: { date: string }) => holiday.date);
      setHolidayDates(filterData);
    }

    if (useDownloadAttendanceMutation.data) {
      setStartDate("");
      setEndDate("");
      setProjectNameSelected("");
      const filterData = useDownloadAttendanceMutation.data?.map(
        (item: any) => {
          const updatedAttendance = item.Attendance?.map((value: any) => {
            const matchingRow = rows?.find(
              (rowData: any) => value.employeeId === rowData.id
            );
            if (matchingRow) {
              return {
                ...value,
                state: holidayDates?.includes(item?.date)
                  ? "HOLIDAY"
                  : value?.state,
                basicSalary: matchingRow.basicSalary,
                overtimePriceHourly: matchingRow.overtimePriceHourly,
                name: matchingRow.name,
                username: matchingRow.username,
              };
            }
            return value;
          });
          return {
            ...item,
            Attendance: updatedAttendance,
          };
        }
      );

      setAttendanceData(filterData);
      setProjectDownloadCard(false);
    } else if (error) {
      showToast(error, "danger");
    }
  }, [
    useAttendaceEmployeeListMutation.data,
    useBulkAttendanceMutation.data,
    useEmployeeAttendanceMutation.data,
    useDownloadAttendanceMutation.data,
    useProjectViewListMutation.data,
    useUpdateAttendanceMutation.data,
    showModal,
    error,
  ]);

  const getIsoDateTimeString = (timeString: any) => {
    const now = new Date();
    const date = now.toISOString().split("T")[0];
    const isoDateTimeString = `${date}T${timeString}:00.000Z`;
    return isoDateTimeString;
  };

  const convertTo12Hour = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  };

  const getMonthDates = (
    monthName: string,
    year: number
  ): {
    startDate: Date;
    endDate: Date;
  } => {
    const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth();
    const startDate = new Date(year, monthIndex, 1);
    const endDate = new Date(year, monthIndex + 1, 0);
    return {
      startDate,
      endDate,
    };
  };

  const toISODateString = (date: any) => {
    const offsetDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    return offsetDate.toISOString();
  };

  useEffect(() => {
    const monthName = currentDate.toLocaleString("default", { month: "long" });
    const { startDate, endDate } = getMonthDates(
      monthName,
      new Date().getFullYear()
    );
    if (viewCalendar) {
      useEmployeeAttendanceMutation.mutate(
        `?employeeId=${userId}&startDate=${toISODateString(
          startDate
        )}&endDate=${toISODateString(endDate)}`
      );
    }
  }, [currentDate]);

  const handleViewCalendar = (id: number, name: string) => {
    setUserId(id);
    setCurrentDate(new Date());
    setUserName(name);
    setViewCalendar(true);
  };

  const handleCloseViewCalendar = () => {
    useEmployeeAttendanceMutation.reset();
    setViewCalendar(false);
    setUserId(0);
  };

  const handleViewCalendarUpdate = () => {
    const monthName = currentDate.toLocaleString("default", { month: "long" });
    const { startDate, endDate } = getMonthDates(
      monthName,
      new Date().getFullYear()
    );
    if (viewCalendar) {
      useEmployeeAttendanceMutation.mutate(
        `?employeeId=${userId}&startDate=${toISODateString(
          startDate
        )}&endDate=${toISODateString(endDate)}`
      );
    }
  };
  const handleSelectDate = (e: any) => {
    const { value } = e.target;
    setTodayDate(new Date(value).toISOString());
  };

  const handleFilterProject = (value: string) => {
    if (value !== "All Projects") {
      const id = useProjectViewListMutation.data?.find(
        (item: any, idx: number) => item.name === value
      )?.id;
      setRows(useAttendaceEmployeeListMutation.data);
      setRows((prvData) =>
        prvData.filter(
          (item) =>
            item?.defaultProjectId === id ||
            item?.Attendance[0]?.projectId === id
        )
      );
    } else {
      setRows(useAttendaceEmployeeListMutation.data);
    }
  };

  const renderFilters = () => (
    <React.Fragment>
      {/* ----------------Filter By Project-------------- */}
      <Box>
        <FormLabel sx={{ marginBottom: "4px", fontSize: "12px" }}>
          Filter By Project
        </FormLabel>
        <Select
          sx={{ maxWidth: "140px", height: "35px" }}
          placeholder={"All Projects"}
          onChange={(event) => {
            if (!event) return;
            const target = event.target as HTMLElement;
            const value = target.textContent || "";
            handleFilterProject(value);
          }}
          size="sm"
        >
          <Option value={"All Projects"}>All Projects</Option>
          {useProjectViewListMutation.data?.map((item: any, idx: number) => (
            <Option key={idx} value={item.name}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Box>
      {/* ----------------Select Date-------------- */}
      <FormControl>
        <FormLabel sx={{ marginBottom: "4px", fontSize: "12px" }}>
          Select Date
        </FormLabel>
        <Input
          name="selectDate"
          sx={{ fontSize: "14px" }}
          type="date"
          defaultValue={new Date(todayDate).toISOString().split("T")[0]}
          onChange={handleSelectDate}
        />
      </FormControl>
      {/* ----------------Bulk Attendance-------------- */}
      <Button
        disabled={selected.length > 0 ? false : true}
        onClick={handleBulkAttendaceBtn}
      >
        Bulk Attendance
      </Button>
      {/* ----------------Project Attendance-------------- */}
      <Button onClick={() => setProjectDownloadCard(true)}>
        Download Attendance
      </Button>
      {attendanceData?.length > 0 && (
        <ExportToExcel data={attendanceData} fileName="Employee Data" />
      )}
    </React.Fragment>
  );

  const handleSearchBtn = (event: any) => {
    const { value } = event.target;
    if (value.length > 0) {
      setRows((prvData) =>
        prvData.filter((item) =>
          item.name?.toLowerCase().includes(value.toLowerCase())
        )
      );
    } else {
      setRows(useAttendaceEmployeeListMutation.data);
    }
  };

  const renderCellContent = (content: string | number, maxWidth: string) => (
    <Tooltip title={content} placement="top">
      <Typography>
        <span
          style={{
            maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          }}
        >
          {content}
        </span>
      </Typography>
    </Tooltip>
  );

  const handleStatusChange = (
    event: React.SyntheticEvent | null,
    projectId: number,
    value: string | null,
    rowData: any
  ) => {
    const formatedDate = getIsoDateTimeString(
      new Date().toTimeString().split(" ")[0].slice(0, 5)
    );
    const dataCheckin = {
      date: todayDate,
      state: value,
      checkInTime: formatedDate,
      projectId: projectId,
      list: [rowData.id],
      remark: value,
    };

    const dataAbsent = {
      date: todayDate,
      state: value,
      projectId: projectId,
      list: [rowData.id],
      remark: value,
    };
    if (rowData.id !== 0) {
      if (value !== "None") {
        if (value === "PRESENT") {
          rowData.Attendance[0]?.state
            ? useUpdateAttendanceMutation.mutate({
                id: rowData.Attendance[0].id,
                newData: { state: "PRESENT" },
              })
            : useBulkAttendanceMutation.mutate(dataCheckin);
        } else if (value === "ABSENT") {
          rowData.Attendance[0]?.state
            ? useUpdateAttendanceMutation.mutate({
                id: rowData.Attendance[0].id,
                newData: { state: "ABSENT" },
              })
            : useBulkAttendanceMutation.mutate(dataAbsent);
        } else if (value === "LEAVE") {
          rowData.Attendance[0]?.state
            ? useUpdateAttendanceMutation.mutate({
                id: rowData.Attendance[0].id,
                newData: { state: "LEAVE" },
              })
            : useBulkAttendanceMutation.mutate(dataAbsent);
        } else if (value === "HALF_DAY") {
          rowData.Attendance[0]?.state
            ? useUpdateAttendanceMutation.mutate({
                id: rowData.Attendance[0].id,
                newData: { state: "HALF_DAY" },
              })
            : useBulkAttendanceMutation.mutate(dataCheckin);
        }
      }
    }
  };

  const renderDropDownCellContent = (
    rowData: any,
    projectId: number,
    content: string,
    maxWidth: string
  ) => (
    <Tooltip title={content} placement="top">
      <Select
        value={content ? content : "None"}
        onChange={(event, value) =>
          handleStatusChange(event, projectId, value, rowData)
        }
        size="sm"
        sx={{
          maxWidth: maxWidth,
          background: "transparent",
          borderRadius: "20px",
          boxShadow: "none",
          border: "none",
          color: "inherit",
          "&:hover": {
            background: "transparent",
          },
        }}
        name="attendanceStatus"
      >
        <Option value="None">None</Option>
        <Option value="PRESENT">PRESENT</Option>
        <Option value="ABSENT">ABSENT</Option>
        <Option value="LEAVE">LEAVE</Option>
        <Option value="HALF_DAY">HALF_DAY</Option>
      </Select>
    </Tooltip>
  );

  const handleChangeProject = (rowData: any, value: string) => {
    if (value) {
      const projecId = useProjectViewListMutation?.data?.find(
        (item: any) => item.name === value
      )?.id;
      const formatedDate = getIsoDateTimeString(
        new Date().toTimeString().split(" ")[0].slice(0, 5)
      );
      const presentWithProject = {
        date: todayDate,
        state: "PRESENT",
        checkInTime: formatedDate,
        projectId: projecId,
        list: [rowData.id],
        remark: value,
      };
      const updateTodayProject = {
        projectId: projecId,
      };
      if (projecId) {
        rowData.Attendance[0]?.projectId
          ? useUpdateAttendanceMutation.mutate({
              id: rowData.Attendance[0].id,
              newData: updateTodayProject,
            })
          : useBulkAttendanceMutation.mutate(presentWithProject);
        useUpdateEmployeeMutation.mutate({
          id: rowData.id,
          newData: { defaultProjectId: projecId },
        });
      }
    }
  };

  const renderProjecDropDown = (
    rowData: any,
    content: string,
    maxWidth: string
  ) => (
    <Tooltip title={content} placement="top">
      <Select
        sx={{ maxWidth: maxWidth }}
        value={String(content)}
        placeholder={content}
        onChange={(event) => {
          if (!event) return;
          const target = event.target as HTMLElement;
          const value = target.textContent || "";
          handleChangeProject(rowData, value);
        }}
        size="sm"
      >
        {useProjectViewListMutation.data?.map((item: any, idx: number) => (
          <Option key={idx} value={item.name}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Tooltip>
  );

  const handleBulkAttendaceCall = (
    event: React.FormEvent<CreateUserFormElement>
  ) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const updatedData: { [key: string]: any } = {};
    if (
      formElements?.startDateTime?.value !== convertTo12Hour(startDateTime) &&
      formElements?.startDateTime?.value !== ""
    ) {
      updatedData.checkInTime = getIsoDateTimeString(
        formElements?.startDateTime?.value
      );
    }
    if (Object.keys(updatedData).length > 0) {
      if (selected.length > 0) {
        selected.forEach((id) => {
          const data = {
            checkInTime: updatedData.checkInTime,
            date: todayDate,
            state: "PRESENT",
            list: [id],
            remark: "",
            projectId: useAttendaceEmployeeListMutation.data?.find(
              (item: any) => item.id === id
            )?.defaultProjectId,
          };
          useBulkAttendanceMutation.mutate(data);
        });
      }
    } else {
      showToast("No change has been made", "danger");
    }
  };

  const handleBulkAttendaceBtn = () => {
    setTimeCardShow(true);
  };

  const handleEditBtn = (usrData: any) => {
    setShowModal(true);
    setAttendaceId(usrData?.Attendance[0]?.id);
    setUserName(usrData?.name);
    setStartDateTime(usrData?.Attendance[0]?.checkInTime);
    setEndDateTime(usrData?.Attendance[0]?.checkOutTime);
    setOverTimeStart(usrData?.Attendance[0]?.overtimeCheckIn);
    setOverTimeEnd(usrData?.Attendance[0]?.overtimeCheckOut);
    setProjectNameSelected(
      useProjectViewListMutation.data?.find(
        (item: any) => item.id === usrData.Attendance[0].projectId
      )?.name
    );
    setProjectName(
      useProjectViewListMutation.data?.find(
        (item: any) => item.id === usrData.Attendance[0].projectId
      )?.name
    );
    setRemark(usrData?.Attendance[0]?.remark);
    setStatus(usrData?.Attendance[0]?.state);
    setAttendanceStatus(usrData?.Attendance[0]?.state);
  };

  const handleUpdateAttendance = (
    event: React.FormEvent<CreateUserFormElement>
  ) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const updatedData: { [key: string]: any } = {};
    if (
      formElements?.startDateTime?.value !== convertTo12Hour(startDateTime) &&
      formElements?.startDateTime?.value !== ""
    ) {
      updatedData.checkInTime = getIsoDateTimeString(
        formElements?.startDateTime?.value
      );
    }
    if (
      formElements?.endDateTime?.value !== convertTo12Hour(endDateTime) &&
      formElements?.endDateTime?.value !== ""
    ) {
      updatedData.checkOutTime = getIsoDateTimeString(
        formElements?.endDateTime?.value
      );
    }
    if (
      formElements?.overTimeStart?.value !== convertTo12Hour(overTimeStart) &&
      formElements?.overTimeStart?.value !== ""
    ) {
      updatedData.overtimeCheckIn = getIsoDateTimeString(
        formElements?.overTimeStart?.value
      );
    }
    if (
      formElements?.overTimeEnd?.value !== convertTo12Hour(overTimeEnd) &&
      formElements?.overTimeEnd?.value !== ""
    ) {
      updatedData.overtimeCheckOut = getIsoDateTimeString(
        formElements?.overTimeEnd?.value
      );
    }
    if (formElements?.remark?.value !== remark && formElements?.remark?.value) {
      updatedData.remark = formElements?.remark?.value;
    }
    if (projectNameSelected !== projectName) {
      updatedData.projectId = useProjectViewListMutation.data?.find(
        (item: any) => item.name === projectNameSelected
      )?.id;
    }
    if (status !== attendanceStatus && status) {
      updatedData.state = attendanceStatus;
    }
    if (Object.keys(updatedData).length > 0) {
      if (attendaceId !== 0) {
        useUpdateAttendanceMutation.mutate({
          id: attendaceId,
          newData: updatedData,
        });
      }
    } else {
      showToast("No change has been made", "danger");
    }
  };

  const handleDownloadAttendance = (
    event: React.FormEvent<CreateUserFormElement>
  ) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    const updatedData: { [key: string]: any } = {};
    if (formElements?.startDate?.value !== "") {
      updatedData.startDate = new Date(
        formElements?.startDate?.value
      ).toISOString();
    }
    if (formElements?.endDate?.value !== "") {
      updatedData.endDate = new Date(
        formElements?.endDate?.value
      ).toISOString();
    }
    if (projectNameSelected !== "") {
      updatedData.projectId = useProjectViewListMutation.data?.find(
        (item: any) => item.name === projectNameSelected
      )?.id;
    } else {
      showToast("Please select project", "danger");
    }
    if (Object.keys(updatedData).length > 0) {
      if (projectNameSelected !== "") {
        useDownloadAttendanceMutation.mutate({
          newData: updatedData,
        });
      }
    } else {
      showToast("No change has been made", "danger");
    }
  };

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          marginBottom: "6px",
          alignItems: "flex-end",
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        {/* ------------search------------ */}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel sx={{ marginBottom: "4px" }}>
            Search for users list
          </FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            onChange={handleSearchBtn}
            sx={{ width: { xs: "100%", md: "40%", sm: "100%" } }}
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked
                        ? rows
                            .filter((row) => row?.Attendance[0] === undefined)
                            .map((row) => row.id)
                        : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              {visibleColumns?.map((item, index) => (
                <th
                  key={index}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                  style={{
                    display: `${item.checked ? "table-cell" : "block"}`,
                    width: item.width,
                    padding: "12px 6px",
                  }}
                >
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                </th>
              ))}
              <th style={{ width: 140, padding: "12px 6px", color: "#0b6bcb" }}>
                Edit
              </th>
            </tr>
          </thead>
          <tbody>
            {useAttendaceEmployeeListMutation.isLoading ? (
              <LoaderBox message="Loading" />
            ) : (
              stableSort(rows, getComparator(order, "id")).map((row, index) => (
                <tr key={index}>
                  <Tooltip
                    color="warning"
                    title={`${
                      validStates.includes(row?.Attendance[0]?.state)
                        ? "Disabled"
                        : ""
                    }`}
                  >
                    <td style={{ textAlign: "center", width: 120 }}>
                      <Checkbox
                        size="sm"
                        style={{
                          cursor: validStates.includes(
                            row?.Attendance[0]?.state
                          )
                            ? "no-drop"
                            : "pointer",
                        }}
                        disabled={
                          validStates.includes(row?.Attendance[0]?.state)
                            ? true
                            : false
                        }
                        checked={selected.includes(row.id)}
                        color={
                          selected.includes(row.id) ? "primary" : undefined
                        }
                        onChange={(event) => {
                          setSelected((ids) =>
                            event.target.checked
                              ? ids.concat(row.id)
                              : ids.filter((itemId) => itemId !== row.id)
                          );
                        }}
                        slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                        sx={{ verticalAlign: "text-bottom" }}
                      />
                    </td>
                  </Tooltip>

                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(row.empoyeeId, "60")}
                    </Typography>
                  </td>

                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(row.name, "140")}
                    </Typography>
                  </td>

                  <td>
                    <Typography level="body-xs">
                      {renderProjecDropDown(
                        row,
                        row.Attendance[0]?.projectId
                          ? useProjectViewListMutation?.data?.find(
                              (item: any) =>
                                item.id === row.Attendance[0]?.projectId
                            )?.name
                          : useProjectViewListMutation?.data?.find(
                              (item: any) => item.id === row.defaultProjectId
                            )?.name,
                        "120px"
                      )}
                    </Typography>
                  </td>

                  <td>
                    <Typography
                      level="body-xs"
                      sx={{
                        textAlign: "center",
                        borderRadius: "20px",
                        paddingLeft: "6px",
                        color:
                          row?.Attendance[0]?.state === "PRESENT"
                            ? "#0a470a"
                            : row?.Attendance[0]?.state === "LEAVE"
                            ? "#7d1212"
                            : row?.Attendance[0]?.state === "ABSENT"
                            ? "#7d1212"
                            : row?.Attendance[0]?.state === "HALF_DAY"
                            ? "#fff"
                            : "#fff",
                        background:
                          row?.Attendance[0]?.state === "PRESENT"
                            ? "#e3fbe3"
                            : row?.Attendance[0]?.state === "LEAVE"
                            ? "#fce4e4"
                            : row?.Attendance[0]?.state === "HALF_DAY"
                            ? "#9995ff"
                            : row?.Attendance[0]?.state === "ABSENT"
                            ? "#fce4e4"
                            : "#000",
                      }}
                    >
                      {renderDropDownCellContent(
                        row,
                        row.Attendance[0]?.projectId
                          ? row.Attendance[0]?.projectId
                          : row.defaultProjectId,
                        row.Attendance[0]?.state
                          ? row.Attendance[0]?.state
                          : "None",
                        "120px"
                      )}
                    </Typography>
                  </td>

                  <td>
                    <Typography
                      level="body-xs"
                      sx={{
                        cursor: "pointer",
                        color: "#0b6bcb",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        handleViewCalendar(row.id, row.name);
                      }}
                    >
                      View Calendar
                    </Typography>
                  </td>

                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(
                        row.Attendance[0]?.checkInTime
                          ? convertToHourTime(row.Attendance[0]?.checkInTime)
                          : "00:00",
                        "140"
                      )}
                    </Typography>
                  </td>

                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(
                        row.Attendance[0]?.checkOutTime
                          ? convertToHourTime(row.Attendance[0]?.checkOutTime)
                          : "00:00",
                        "140"
                      )}
                    </Typography>
                  </td>

                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(
                        row?.Attendance[0]?.overtimeCheckIn
                          ? convertToHourTime(
                              row?.Attendance[0]?.overtimeCheckIn
                            )
                          : "00:00",
                        "140"
                      )}
                    </Typography>
                  </td>

                  <td>
                    <Typography level="body-xs">
                      {renderCellContent(
                        row?.Attendance[0]?.overtimeCheckOut
                          ? convertToHourTime(
                              row?.Attendance[0]?.overtimeCheckOut
                            )
                          : "00:00",
                        "180"
                      )}
                    </Typography>
                  </td>
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        marginLeft: "5px",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        level="body-xs"
                        component="button"
                        disabled={row?.Attendance.length > 0 ? false : true}
                      >
                        <Tooltip
                          title={
                            row?.Attendance.length > 0 ? "Edit" : "Disabled"
                          }
                          placement="top"
                        >
                          <CreateRoundedIcon
                            sx={{
                              color:
                                row?.Attendance.length > 0 ? "coral" : "gray",
                              fontSize: "20px",
                              borderRadius: "20px",
                              "&:hover": {
                                padding: "2px",
                                backgroundColor: "coral",
                                color: "white",
                              },
                            }}
                            onClick={() => handleEditBtn(row)}
                          />
                        </Tooltip>
                      </Link>
                    </Box>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Sheet>
      {/* ---------------Calendar--------------- */}
      <Calendar
        open={viewCalendar}
        title={`${
          userName === undefined
            ? "Calendar"
            : `Calendar - (${userName?.toLowerCase()})`
        }`}
        setOpen={setViewCalendar}
        currentDate={currentDate}
        userId={userId}
        setCurrentDate={setCurrentDate}
        handleEditBtn={handleEditBtn}
        projectNameList={useProjectViewListMutation?.data}
        data={employeCalendarData}
        handleCloseViewCalendar={handleCloseViewCalendar}
      />
      {/* ---------------Attendance--------------- */}
      <EditFormModal
        open={showModal}
        setOpen={setShowModal}
        title={`${
          userName === undefined
            ? "Attendace"
            : `Attendace - (${userName?.toLowerCase()})`
        }`}
        statusCard={true}
        attendanceStatus={attendanceStatus}
        startDateTimeCard={true}
        startDateTime={startDateTime}
        endDateTimeCard={true}
        endDateTime={endDateTime}
        overTimeStartCard={true}
        overTimeStart={overTimeStart}
        overTimeEndCard={true}
        overTimeEnd={overTimeEnd}
        remarkCard={true}
        isLoading={useUpdateAttendanceMutation.isLoading}
        remark={remark}
        setSelectedStatus={setAttendanceStatus}
        projectNameStatusCard={true}
        projectNameList={projectNameList}
        projectNameSelected={projectNameSelected}
        setProjectNameSelected={setProjectNameSelected}
        onSubmit={handleUpdateAttendance}
      />
      {/* ---------------Attendance Download--------------- */}
      <EditFormModal
        open={projectDownloadCard}
        setOpen={setProjectDownloadCard}
        title={`Attendace Download`}
        startDateCard={true}
        startDate={startDate}
        endDateCard={true}
        endDate={endDate}
        projectNameStatusCard={true}
        projectNameList={projectNameList}
        projectNameSelected={projectNameSelected}
        setProjectNameSelected={setProjectNameSelected}
        onSubmit={handleDownloadAttendance}
      />
      {/* ---------------Attendance Bulk with time--------------- */}
      <EditFormModal
        open={timeCardShow}
        setOpen={setTimeCardShow}
        isLoading={useBulkAttendanceMutation.isLoading}
        startDateTime={startDateTime}
        startDateTimeCard={true}
        title={"Bulk Attendance"}
        onSubmit={handleBulkAttendaceCall}
      />
      {/* ---------------pagination--------------- */}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: "flex",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box
          sx={{
            flex: 1,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        />
        {["1", "2", "3", "…"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box
          sx={{
            flex: 1,
          }}
        />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
