import React, { useEffect, useState } from "react";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Divider from "@mui/joy/Divider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Link from "@mui/joy/Link";
import Input from "@mui/joy/Input";
import Modal from "@mui/joy/Modal";
import ModalDialog from "@mui/joy/ModalDialog";
import ModalClose from "@mui/joy/ModalClose";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import Sheet from "@mui/joy/Sheet";
import Checkbox from "@mui/joy/Checkbox";
import IconButton, { iconButtonClasses } from "@mui/joy/IconButton";
import Typography from "@mui/joy/Typography";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useApi } from "../../Auth/api/ApiContext";
import { useAuth } from "../../Auth/utils/useAuth";
import {
  CreateUserFormElement,
  StatementDto,
  ViewEmployeeDto,
} from "../../types";
import { Tooltip, useColorScheme } from "@mui/joy";
import { EditFormModal } from "../globalCards/ModalBox";
import { LoaderBox, NotfoundBox } from "../globalCards/LoaderBox";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Statement() {
  const {
    useViewEmployeeListMutation,
    useAdvancePayMutation,
    useAdvanceRecivedMutation,
    useGetEmployeeStatment,
    error,
  } = useApi();
  const [visibleColumns, setVisibleColumns] = useState([
    {
      checked: true,
      width: 60,
      name: "ID",
    },
    {
      checked: true,
      width: 140,
      name: "Name",
    },
    {
      checked: true,
      width: 140,
      name: "Balance",
    },
    {
      checked: true,
      width: 120,
      name: "Creadit",
    },
    {
      checked: true,
      width: 120,
      name: "Debit",
    },
    {
      checked: true,
      width: 140,
      name: "Statment",
    },
  ]);
  const { showToast } = useAuth();
  const [rows, setRows] = useState<ViewEmployeeDto[]>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [selected, setSelected] = useState<number[]>([]);
  const [open, setOpen] = useState(false);
  const [showPay, setShowPay] = useState(false);
  const [showRecived, setShowRecived] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [remark] = useState("");
  const [amount] = useState();
  const [userId, setUserId] = useState<number>();
  const [statmentData, setStatmentData] = useState<StatementDto[]>([]);
  const [endDate, setEndDate] = useState<string>(new Date().toISOString());
  const setTodayDateToFirstOfMonth = (value: Date): string => {
    const date = new Date(value);
    date.setDate(1);
    return date.toISOString();
  };

  const [todayDate, setTodayDate] = useState<string>(
    setTodayDateToFirstOfMonth(new Date())
  );

  useEffect(() => {
    useViewEmployeeListMutation.mutate();
  }, []);

  useEffect(() => {
    if (useViewEmployeeListMutation.data) {
      setRows(useViewEmployeeListMutation.data);
    }
    if (useAdvancePayMutation.data) {
      useViewEmployeeListMutation.mutate();
      showToast("Updated", "success");
      useAdvancePayMutation.reset();
      useGetEmployeeStatment.reset();
      setShowPay(false);
    }
    if (useAdvanceRecivedMutation.data) {
      useViewEmployeeListMutation.mutate();
      showToast("Updated", "success");
      useGetEmployeeStatment.reset();
      useAdvanceRecivedMutation.reset();
      setShowRecived(false);
    }
    if (useGetEmployeeStatment.data) {
      setStatmentData(useGetEmployeeStatment.data);
      setShowStatement(true);
      useGetEmployeeStatment.reset();
    }
    if (error) {
      showToast(error, "danger");
    }
  }, [
    useViewEmployeeListMutation.data,
    useAdvancePayMutation.data,
    useAdvanceRecivedMutation.data,
    useGetEmployeeStatment.data,
    error,
  ]);

  const handleAdvanceRecived = (id: number) => {
    setUserId(id);
    setShowRecived(true);
  };
  const handleAdvancePay = (id: number) => {
    setUserId(id);
    setShowPay(true);
  };

  const handleViewStatement = (id: number) => {
    const getEmployeInfo = {
      id: id,
      startDate: todayDate,
      endDate: endDate,
    };
    if (id) {
      useGetEmployeeStatment.mutate(getEmployeInfo);
    }
  };

  const handleSelectDate = (e: any) => {
    const { name, value } = e.target;
    if (name === "selectStartDate") {
      setTodayDate(new Date(value).toISOString());
    } else if (name === "selectEndDate") {
      setEndDate(new Date(value).toISOString());
    }
  };

  const handleCheckboxChange = (columnName: string) => {
    setVisibleColumns((prevColumns) =>
      prevColumns?.map((column) =>
        column.name === columnName
          ? { ...column, checked: !column.checked }
          : column
      )
    );
  };

  const handleRecived = (event: React.FormEvent<CreateUserFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    if (!formElements.amount.value) {
      showToast("Please enter amount.", "warning");
      return;
    }
    if (!formElements.remark.value) {
      showToast("Please enter remark.", "warning");
      return;
    }

    const data = {
      amount: Number(formElements.amount.value),
      note: formElements.remark.value,
    };
    if (data) {
      useAdvanceRecivedMutation.mutate({ id: userId, newData: data });
    }
  };

  const handlePay = (event: React.FormEvent<CreateUserFormElement>) => {
    event.preventDefault();
    const formElements = event.currentTarget.elements;
    if (!formElements.amount.value) {
      showToast("Please enter amount.", "warning");
      return;
    }
    if (!formElements.remark.value) {
      showToast("Please enter remark.", "warning");
      return;
    }
    const data = {
      amount: Number(formElements.amount.value),
      note: formElements.remark.value,
    };
    if (data) {
      useAdvancePayMutation.mutate({ id: userId, newData: data });
    }
  };

  const convertDate = (dateStr: string): string => {
    const date = new Date(dateStr);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const formattedDate = date
      .toLocaleDateString("en-GB", options)
      .replace(",", "");

    return formattedDate;
  };

  const renderFilters = () => (
    <React.Fragment>
      {/* ----------------Select Date-------------- */}
      <FormControl>
        <FormLabel sx={{ marginBottom: "0px" }}>Start Date</FormLabel>
        <Input
          name="selectStartDate"
          sx={{ fontSize: "14px" }}
          type="date"
          defaultValue={new Date(todayDate).toISOString().split("T")[0]}
          onChange={handleSelectDate}
        />
      </FormControl>
      <FormControl>
        <FormLabel sx={{ marginBottom: "0px" }}>End Date</FormLabel>
        <Input
          name="selectEndDate"
          sx={{ fontSize: "14px" }}
          type="date"
          defaultValue={new Date(endDate).toISOString().split("T")[0]}
          onChange={handleSelectDate}
        />
      </FormControl>
      {/* ----------------columns-------------- */}
      <Box>
        <FormLabel>Columns</FormLabel>
        <Select size="sm" placeholder="All">
          {visibleColumns?.map((column) => (
            <Option key={column.name} value={column.name}>
              <Checkbox
                label={column.name}
                checked={column.checked}
                onChange={() => handleCheckboxChange(column.name)}
              />
            </Option>
          ))}
        </Select>
      </Box>
    </React.Fragment>
  );

  const handleSearchBtn = (event: any) => {
    const { value } = event.target;
    if (value.length > 0) {
      setRows((prvData) =>
        prvData.filter(
          (item) =>
            item.name?.toLowerCase().includes(value.toLowerCase()) ||
            item.email?.toLowerCase().includes(value.toLowerCase()) ||
            item.designation?.toLowerCase().includes(value.toLowerCase()) ||
            item.username?.toLowerCase().includes(value.toLowerCase()) ||
            item.phone?.includes(value)
        )
      );
    } else {
      setRows(useViewEmployeeListMutation.data);
    }
  };
  const renderCellContent = (content: string | number, maxWidth: string) => (
    <Tooltip title={content} placement="top">
      <Typography>
        <span
          style={{
            maxWidth,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "block",
          }}
        >
          {content}
        </span>
      </Typography>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: { xs: "flex", sm: "none" },
          my: 1,
          gap: 1,
        }}
      >
        <Input
          size="sm"
          placeholder="Search"
          startDecorator={<SearchIcon />}
          sx={{ flexGrow: 1 }}
        />
        <IconButton
          size="sm"
          variant="outlined"
          color="neutral"
          onClick={() => setOpen(true)}
        >
          <FilterAltIcon />
        </IconButton>
        <Modal open={open} onClose={() => setOpen(false)}>
          <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
            <ModalClose />
            <Typography id="filter-modal" level="h2">
              Filters
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {renderFilters()}
              <Button color="primary" onClick={() => setOpen(false)}>
                Submit
              </Button>
            </Sheet>
          </ModalDialog>
        </Modal>
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: { xs: "none", sm: "flex" },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: { xs: "120px", md: "160px" },
          },
        }}
      >
        {/* ------------search------------ */}
        <FormControl sx={{ flex: 1 }} size="sm">
          <FormLabel>Search for employee list</FormLabel>
          <Input
            size="sm"
            placeholder="Search"
            onChange={handleSearchBtn}
            sx={{ width: { xs: "100%", md: "40%", sm: "100%" } }}
            startDecorator={<SearchIcon />}
          />
        </FormControl>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial", sm: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Table
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
          }}
        >
          <thead>
            <tr>
              <th
                style={{ width: 48, textAlign: "center", padding: "12px 6px" }}
              >
                <Checkbox
                  size="sm"
                  indeterminate={
                    selected.length > 0 && selected.length !== rows.length
                  }
                  checked={selected.length === rows.length}
                  onChange={(event) => {
                    setSelected(
                      event.target.checked ? rows.map((row) => row.id) : []
                    );
                  }}
                  color={
                    selected.length > 0 || selected.length === rows.length
                      ? "primary"
                      : undefined
                  }
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </th>
              {visibleColumns?.map((item, index) => (
                <th
                  key={index}
                  onClick={() => setOrder(order === "asc" ? "desc" : "asc")}
                  style={{
                    display: `${item.checked ? "table-cell" : "block"}`,
                    width: item.width,
                    padding: "12px 6px",
                  }}
                >
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    fontWeight="lg"
                    endDecorator={<ArrowDropDownIcon />}
                    sx={{
                      "& svg": {
                        transition: "0.2s",
                        transform:
                          order === "asc" ? "rotate(180deg)" : "rotate(0deg)",
                      },
                    }}
                  >
                    {item.name}
                  </Link>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {useViewEmployeeListMutation.isLoading ? (
              <LoaderBox message="Loading" />
            ) : (
              stableSort(rows, getComparator(order, "id")).map((row, index) => (
                <tr key={index}>
                  <td style={{ textAlign: "center", width: 120 }}>
                    <Checkbox
                      size="sm"
                      checked={selected.includes(row.id)}
                      color={selected.includes(row.id) ? "primary" : undefined}
                      onChange={(event) => {
                        setSelected((ids) =>
                          event.target.checked
                            ? ids.concat(row.id)
                            : ids.filter((itemId) => itemId !== row.id)
                        );
                      }}
                      slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                      sx={{ verticalAlign: "text-bottom" }}
                    />
                  </td>
                  {visibleColumns[0].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.id, "60")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[1].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.name, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[2].checked && (
                    <td>
                      <Typography level="body-xs">
                        {renderCellContent(row.advanceAmount, "140")}
                      </Typography>
                    </td>
                  )}
                  {visibleColumns[3].checked && (
                    <td>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                        onClick={() => handleAdvanceRecived(row.id)}
                      >
                        <Link level="body-xs" component="button">
                          Recived
                        </Link>
                      </Box>
                    </td>
                  )}
                  {visibleColumns[4].checked && (
                    <td>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                        onClick={() => handleAdvancePay(row.id)}
                      >
                        <Link level="body-xs" component="button">
                          Pay
                        </Link>
                      </Box>
                    </td>
                  )}
                  {visibleColumns[5].checked && (
                    <td>
                      <Box
                        sx={{ display: "flex", gap: 2, alignItems: "center" }}
                        onClick={() => handleViewStatement(row.id)}
                      >
                        <Link level="body-xs" component="button">
                          View
                        </Link>
                      </Box>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Sheet>
      {/* -----------------Pay-------------- */}
      <EditFormModal
        open={showPay}
        setOpen={setShowPay}
        title={"Advance Pay"}
        amountCard={true}
        amount={amount}
        remarkCard={true}
        remark={remark}
        onSubmit={handlePay}
      />
      {/* -----------------Recived-------------- */}
      <EditFormModal
        open={showRecived}
        setOpen={setShowRecived}
        amountCard={true}
        amount={amount}
        remarkCard={true}
        remark={remark}
        title={"Advance Recived"}
        onSubmit={handleRecived}
      />
      {/* -------------Statment-------------- */}
      <Modal open={showStatement} onClose={() => setShowStatement(false)}>
        <ModalDialog
          sx={{
            marginLeft: { sm: "5%", xs: "0%" },
            marginTop: { sm: "0%", xs: "5%" },
            overflow: "scroll",
            height: "50%",
            padding: "0px 20px",
          }}
        >
          {!useGetEmployeeStatment.isLoading ? (
            <Table aria-label="basic table">
              <thead style={{ position: "sticky", top: "0" }}>
                <tr>
                  <th>Date</th>
                  <th style={{ width: "40%" }}>Name</th>
                  <th>Credit</th>
                  <th>Debit</th>
                </tr>
              </thead>
              <tbody>
                {statmentData.length > 0 ? (
                  statmentData?.map((item: any, index: number) => (
                    <tr key={index}>
                      <td>{convertDate(item.createdAt)}</td>
                      <td>{item.note}</td>
                      <td>{item.amount > 0 ? item.amount : "-"}</td>
                      <td>{item.amount < 0 ? Math.abs(item.amount) : "-"}</td>
                    </tr>
                  ))
                ) : (
                  <NotfoundBox />
                )}
              </tbody>
            </Table>
          ) : (
            <Button loading loadingPosition="start">
              Loading
            </Button>
          )}
        </ModalDialog>
      </Modal>
      {/* -------------pagination-------------- */}
      <Box
        className="Pagination-laptopUp"
        sx={{
          pt: 2,
          gap: 1,
          [`& .${iconButtonClasses.root}`]: { borderRadius: "50%" },
          display: "flex",
        }}
      >
        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          startDecorator={<KeyboardArrowLeftIcon />}
        >
          Previous
        </Button>

        <Box
          sx={{
            flex: 1,
            display: {
              xs: "none",
              md: "flex",
            },
          }}
        />
        {["1", "2", "3", "…"].map((page) => (
          <IconButton
            key={page}
            size="sm"
            variant={Number(page) ? "outlined" : "plain"}
            color="neutral"
          >
            {page}
          </IconButton>
        ))}
        <Box
          sx={{
            flex: 1,
          }}
        />

        <Button
          size="sm"
          variant="outlined"
          color="neutral"
          endDecorator={<KeyboardArrowRightIcon />}
        >
          Next
        </Button>
      </Box>
    </React.Fragment>
  );
}
