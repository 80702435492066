import React, { createContext, useContext, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { getData, postData, deleteData, patchData } from "./apiService";
import { AnyNsRecord } from "dns";

type ApiContextType = {
  addEmployeeResponse: any;
  employeeSalaryPdfListResponse: any;
  createUserResponse: any;
  updateUserResponse: any;
  useUpdateUserMutation: any;
  signInResponse: any;
  resetPasswordResponse: any;
  sendVerifyLinkResponse: any;
  useBulkEmployeeSalaryMutation: any;
  useDeleteCompanyMutation: any;
  useDeleteProjectMutation: any;
  useGetEmployeeStatment: any;
  useAdvancePayMutation: any;
  useAdvanceRecivedMutation: any;
  getByIdResponse: any;
  deletedUserResponse: any;
  getUserListResponse: any;
  useUpdateEmployeeMutation: any;
  useUserGetMutation: any;
  useUpdateCompanyMutation: any;
  useDownloadAttendanceMutation: any;
  useViewEmployeeListMutation: any;
  useEmployeeSalaryMutation: any;
  useUpdatePermissionMutation: any;
  useUpdateAttendanceMutation: any;
  useDateVisAttendaceEmployee: any;
  useUpdateHolidayCalendrMutation: any;
  useUpdateProjectStatusMutation: any;
  useCalendarViewMutation: any;
  useAddProjectMutation: any;
  useUpdateProjectMutation: any;
  useProjectViewListMutation: any;
  useHolidayCalendarViewMutation: any;
  useSignInMutation: any;
  useAddCompanyMutation: any;
  useSendVerifyLinkMutation: any;
  useGetUserListResponseMutation: any;
  useCompanyViewListMutation: any;
  useResetPasswordMutation: any;
  useUserListMutation: any;
  useAttendaceEmployeeListMutation: any;
  useCreateUserMutation: any;
  useEmployeeAttendanceMutation: any;
  useBulkAttendanceMutation: any;
  useEmpSalaryDownloadMutation: any;
  useAddEmployeeMutation: any;
  useDeleteUserMutation: any;
  useUserListIdMutation: any;
  useDeleteEmployeeMutation: any;
  useGetByIdMutation: any;
  setError: any;
  useVendorViewListMutation: any;
  useAddVendorMutation: any;
  useUpdateVendorMutation: any;
  useDeleteVendorMutation: any;
  useSupplierViewListMutation: any;
  useAddSupplierMutation: any;
  useUpdateSupplierMutation: any;
  useDeleteSupplierMutation: any;
  useCompanyExpensesListMutation: any;
  useAddCompanyExpensesMutation: any;
  useUpdateCompanyExpensesMutation: any;
  useDeleteCompanyExpensesMutation: any;
  useOperationalExpensesListMutation: any;
  useAddOperationalExpensesMutation: any;
  useUpdateOperationalExpensesMutation: any;
  useDeleteOperationalExpensesMutation: any;
  error: string | null; // Add error to context type
};

const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const [error, setError] = useState<string | null>(null);

  const useAddEmployeeMutation = useMutation(
    (newData: any) => postData("/employees", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData");
        setError(null);
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUserGetMutation = useMutation(
    (newData: any) => getData(`/users/me`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useViewEmployeeListMutation = useMutation(
    (newData: any) => getData("/employees"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData");
        setError(null);
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useSignInMutation = useMutation(
    (newData: any) => postData("/auth/signin", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useGetUserListResponseMutation = useMutation(
    (newData: any) => getData(`/users`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useEmpSalaryDownloadMutation = useMutation(
    (newData: any) => getData(`/employees${newData}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useEmployeeAttendanceMutation = useMutation(
    (newData: any) => getData(`/attendance/${newData}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateHolidayCalendrMutation = useMutation(
    (newData: any) => patchData("/calendar/day", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useCalendarViewMutation = useMutation(
    (newData: any) => getData(`calendar/day?date=${newData}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useHolidayCalendarViewMutation = useMutation(
    ({
      status,
      startDate,
      endDate,
    }: {
      status: string;
      startDate: any;
      endDate: any;
    }) =>
      getData(
        `/calendar?status=${status}&startDate=${startDate}&endDate=${endDate}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useCompanyViewListMutation = useMutation(
    (newData: any) => getData("/company"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAddCompanyMutation = useMutation(
    (newData: any) => postData("/company", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateCompanyMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/company/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useDeleteCompanyMutation = useMutation(
    (id: string) => deleteData(`/company/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );
  const useDeleteProjectMutation = useMutation(
    (id: string) => deleteData(`/project/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );
  const useAddProjectMutation = useMutation(
    (newData: any) => postData("/project", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateProjectMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/project/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateProjectStatusMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/project/${id}/status`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useVendorViewListMutation = useMutation(
    (newData: any) => getData("/vendor"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAddVendorMutation = useMutation(
    (newData: any) => postData("/vendor", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateVendorMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/vendor/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useDeleteVendorMutation = useMutation(
    (id: string) => deleteData(`/vendor/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );

  const useCompanyExpensesListMutation = useMutation(
    (newData: any) => getData("/expanses/company"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAddCompanyExpensesMutation = useMutation(
    (newData: any) => postData("/expanses/company", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateCompanyExpensesMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/expanses/company/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useDeleteCompanyExpensesMutation = useMutation(
    (id: string) => deleteData(`/expanses/company/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );

  const useSupplierViewListMutation = useMutation(
    (newData: any) => getData("/supplier"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAddSupplierMutation = useMutation(
    (newData: any) => postData("/supplier", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateSupplierMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/supplier/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useDeleteSupplierMutation = useMutation(
    (id: string) => deleteData(`/supplier/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );
  const useProjectViewListMutation = useMutation(
    (newData: any) => getData("/project"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateAttendanceMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/attendance/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during the POST request"
        );
      },
    }
  );

  const useDownloadAttendanceMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      getData(
        `/attendance?startDate=${newData.startDate}&endDate=${newData.endDate}&projectId=${newData.projectId}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during the POST request"
        );
      },
    }
  );

  const useSendVerifyLinkMutation = useMutation(
    (newData: any) => postData("/auth/reset-password/mock", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useEmployeeSalaryMutation = useMutation(
    ({ id, startDate, endDate }: { id: any; startDate: any; endDate: any }) =>
      getData(
        `employees/salary?startDate=${startDate}&endDate=${endDate}&employeeIds=${id}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useBulkEmployeeSalaryMutation = useMutation(
    ({ id, startDate, endDate }: { id: any; startDate: any; endDate: any }) =>
      getData(
        `employees/salary?startDate=${startDate}&endDate=${endDate}&employeeIds=${id}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useBulkAttendanceMutation = useMutation(
    (newData: any) => postData("/attendance", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAdvancePayMutation = useMutation(
    ({ id, newData }: { id: any; newData: any }) =>
      postData(`/employees/${id}/credit_advance`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAdvanceRecivedMutation = useMutation(
    ({ id, newData }: { id: any; newData: any }) =>
      postData(`/employees/${id}/debit_advance`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useCreateUserMutation = useMutation(
    (newData: any) => postData("/users", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAttendaceEmployeeListMutation = useMutation(
    (newData: any) => getData(`/employees/attendance?date=${newData}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useDateVisAttendaceEmployee = useMutation(
    ({
      id,
      startDate,
      endDate,
    }: {
      id: number;
      startDate: string;
      endDate: string;
    }) =>
      getData(
        `/attendance?startDate=${startDate}&endDate=${endDate}&employeeId=${id}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );
  const useGetEmployeeStatment = useMutation(
    ({
      id,
      startDate,
      endDate,
    }: {
      id: number;
      startDate: string;
      endDate: string;
    }) =>
      getData(
        `/employees/advance_transactions?startDate=${startDate}&endDate=${endDate}&employeesId=${id}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUserListMutation = useMutation((newData: any) => getData("/users"), {
    onSuccess: () => {
      queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
      setError(null); // Clear error on success
    },
    onError: (error: any) => {
      setError(error?.response?.data?.message || "Server Error");
    },
  });

  const useUpdateUserMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/users/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during the POST request"
        );
      },
    }
  );

  const useUpdatePermissionMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/users/permissions/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during the POST request"
        );
      },
    }
  );
  const useResetPasswordMutation = useMutation(
    (newData: any) => postData("/auth/reset-password/finish", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );
  const useUserListIdMutation = useMutation(
    (newData: any) => getData(`/users/${newData}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateEmployeeMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/employees/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during the POST request"
        );
      },
    }
  );

  const useDeleteEmployeeMutation = useMutation(
    (id: string) => deleteData(`/employees/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );

  const useOperationalExpensesListMutation = useMutation(
    (newData: any) => getData("/expanses/operational"),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useAddOperationalExpensesMutation = useMutation(
    (newData: any) => postData("/expanses/operational", newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useUpdateOperationalExpensesMutation = useMutation(
    ({ id, newData }: { id: number; newData: any }) =>
      patchData(`/expanses/operational/${id}`, newData),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after POST
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(error?.response?.data?.message || "Server Error");
      },
    }
  );

  const useDeleteOperationalExpensesMutation = useMutation(
    (id: string) => deleteData(`/expanses/operational/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );

  const useDeleteUserMutation = useMutation(
    (id: string) => deleteData(`/users/${id}`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("sendData"); // Refresh GET data after DELETE
        setError(null); // Clear error on success
      },
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during DELETE request"
        );
      },
    }
  );

  const useGetByIdMutation = useMutation(
    (id: string) => getData(`/get-endpoint/${id}`),
    {
      onError: (error: any) => {
        setError(
          error?.response?.data?.message ||
            "An error occurred during GET by ID request"
        );
      },
    }
  );

  const contextValue = {
    addEmployeeResponse: useAddEmployeeMutation.data,
    deleteResponse: useDeleteUserMutation.data,
    getByIdResponse: useGetByIdMutation.data,
    signInResponse: useSignInMutation.data,
    sendVerifyLinkResponse: useSendVerifyLinkMutation.data,
    resetPasswordResponse: useResetPasswordMutation.data,
    employeeSalaryPdfListResponse: useEmpSalaryDownloadMutation.data,
    createUserResponse: useCreateUserMutation.data,
    getUserListResponse: useGetUserListResponseMutation.data,
    updateUserResponse: useUpdateUserMutation.data,
    deletedUserResponse: useDeleteUserMutation.data,
    useBulkAttendanceMutation,
    useUpdateUserMutation,
    useCreateUserMutation,
    useGetUserListResponseMutation,
    useDeleteUserMutation,
    useViewEmployeeListMutation,
    useResetPasswordMutation,
    useSendVerifyLinkMutation,
    useAddEmployeeMutation,
    useEmpSalaryDownloadMutation,
    useDeleteEmployeeMutation,
    useSignInMutation,
    useUserListMutation,
    useAttendaceEmployeeListMutation,
    useUpdateHolidayCalendrMutation,
    useCalendarViewMutation,
    useEmployeeSalaryMutation,
    useEmployeeAttendanceMutation,
    useUpdateEmployeeMutation,
    useUserListIdMutation,
    useHolidayCalendarViewMutation,
    useUpdateAttendanceMutation,
    useGetByIdMutation,
    useAddCompanyMutation,
    useUpdateCompanyMutation,
    useCompanyViewListMutation,
    useAddProjectMutation,
    useDownloadAttendanceMutation,
    useDateVisAttendaceEmployee,
    useUpdateProjectMutation,
    useUserGetMutation,
    useProjectViewListMutation,
    useUpdateProjectStatusMutation,
    useBulkEmployeeSalaryMutation,
    useAdvancePayMutation,
    useGetEmployeeStatment,
    useAdvanceRecivedMutation,
    useDeleteCompanyMutation,
    useDeleteProjectMutation,
    useUpdatePermissionMutation,
    useVendorViewListMutation,
    useAddVendorMutation,
    useUpdateVendorMutation,
    useDeleteVendorMutation,
    useSupplierViewListMutation,
    useAddSupplierMutation,
    useUpdateSupplierMutation,
    useDeleteSupplierMutation,
    useCompanyExpensesListMutation,
    useAddCompanyExpensesMutation,
    useUpdateCompanyExpensesMutation,
    useDeleteCompanyExpensesMutation,
    useOperationalExpensesListMutation,
    useAddOperationalExpensesMutation,
    useUpdateOperationalExpensesMutation,
    useDeleteOperationalExpensesMutation,
    setError,
    error,
  };

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

export const useApi = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};
