import React from "react";
import { useMediaQuery } from "@mui/material";
import { LoaderBoxProps } from "../../types";
import { Box, Button } from "@mui/joy";

export function LoaderBox({ message }: LoaderBoxProps) {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <tr>
      <td colSpan={isMobile ? 1 : 7}>
        <Box
          sx={{
            display: "block",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Button loading loadingPosition="start">
            {message}
          </Button>
        </Box>
      </td>
    </tr>
  );
}

export function NotfoundBox() {
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <tr>
      <td colSpan={isMobile ? 1 : 7}>
        <span
          style={{
            display: "block",
            textAlign: "center",
            width: "100%",
          }}
        >
          No Data found
        </span>
      </td>
    </tr>
  );
}
